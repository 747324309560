import getConfig from 'next/config';
import { post } from '../../http/http';

const {
  publicRuntimeConfig: { isRunningCypress },
} = getConfig();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function createLead(bodyData): Promise<any> {
  // skip if Cypress is running
  if (isRunningCypress === 'true') return;

  let response: Response;

  try {
    response = await post<Response>(`/api/leadgen/createUser`, bodyData);
    return response;
  } catch (error) {
    // TODO
    throw error;
  }
}
