import React, { useState, useEffect } from 'react';
// import { useQuery, gql } from '@apollo/client';
import _groupBy from 'lodash/groupBy';
import _find from 'lodash/find';
import { Field, useFormikContext } from 'formik';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@fox/components/Typography';
import Autocomplete from '@fox/components/Autocomplete';
import theme from '@fox/Theme/Theme';
import withStore from '@components/Store/withStore/withStore';
import { I18n } from 'src/types';
import {
  getCurrencyList,
  getMajorCurrencyList,
} from '@services/currencyList/currencyList';
import validationMessages from '../../i18n/validationMessages';
import Alert from 'src/fox/components/Alert';
import { notifyFieldError } from 'src/util/analytics/googleTagManager';
import defaultCurrencies from './defaultCurrencies';

interface CurrenciesProps {
  i18n: I18n;
  initialValues: {
    fromCurrency: string;
    toCurrency: string;
  };
  setShowSystemErrorMessage?: (showSystemErrorMessage: boolean) => void;
  gcaOnboarding?: boolean;
}

interface ParsedCurrencyData {
  value: string;
  label: string;
  isPopular: boolean;
  isMajor: boolean;
}

interface FormValues {
  fromCurrency: string;
  toCurrency: string;
}

const validate = (
  selectedCurrency,
  supportedCurrencies,
  i18n: I18n,
  field
): string => {
  if (!selectedCurrency) return validationMessages.currency.fieldRequired;

  if (
    !supportedCurrencies.find(
      (supportedCurrency) => supportedCurrency.value === selectedCurrency
    )
  ) {
    return field === 'toCurrency'
      ? i18n.validationMessages.currency.unsupportedTo
      : i18n.validationMessages.currency.unsupportedFrom;
  }

  return '';
};

const parseCurrencyData = (currency): ParsedCurrencyData =>
  currency.divider
    ? currency
    : {
        value: currency.Code,
        label: `${currency.Name} (${currency.Code})`,
        isPopular: currency.IsPopularCurrency,
        isMajor: currency.IsCustomerMajor,
      };

const parseCurrencyList = (currencies): ParsedCurrencyData[] => {
  const groupByPopular = _groupBy(currencies, (c) => c.IsPopularCurrency);

  const sortedList = [
    ...groupByPopular['true'],
    { label: '', divider: true },
    ...groupByPopular['false'],
  ].reduce((r, c) => [...r, parseCurrencyData(c)], []) as ParsedCurrencyData[];

  return sortedList;
};

// Leaving this here as this is the query we'll use:
// export const GET_CURRENCIES = gql`
//   query GetCurrencies {
//     currencies {
//       code
//       name
//       isMajor
//       isPopular
//     }
//   }
// `;

const Currencies = ({
  i18n,
  initialValues,
  setShowSystemErrorMessage,
  gcaOnboarding,
}: CurrenciesProps): React.ReactElement => {
  const [allCurrencies, setAllCurrencies] = useState(defaultCurrencies);
  const [supportedCurrencies, setSupportedCurrencies] =
    useState(defaultCurrencies);
  const [fromCurrencies, setFromCurrencies] = useState(defaultCurrencies);
  const [toCurrencies, setToCurrencies] = useState(defaultCurrencies);
  const [toCurrenciesError, setToCurrenciesError] = useState(false);
  const [fromCurrenciesError, setFromCurrenciesError] = useState(false);
  const matchError = i18n.validationMessages.currency.currencyMatch;
  // Example of a client side request, using the useQuery hook:
  // const { data, loading, error } = useQuery(GET_CURRENCIES);

  const {
    values: { fromCurrency, toCurrency },
  } = useFormikContext<FormValues>();

  useEffect(() => {
    if (supportedCurrencies.length <= 0) return;

    const isSupported = (value): boolean =>
      _find(supportedCurrencies, { value }) !== undefined;

    if (!isSupported(fromCurrency) && fromCurrency) {
      notifyFieldError({ field: 'text-fromCurrency', value: fromCurrency });
    }

    if (!isSupported(toCurrency) && toCurrency) {
      notifyFieldError({ field: 'text-toCurrency', value: toCurrency });
    }
  }, [fromCurrency, toCurrency]);

  useEffect(() => {
    async function getCurrencies(): Promise<void> {
      try {
        const [_all, _major] = await Promise.all([
          getCurrencyList(),
          getMajorCurrencyList(),
        ]);

        const parsedAll = parseCurrencyList(_all.Currencies);
        const parsedMajor = parseCurrencyList(_major.Currencies);

        setAllCurrencies(parsedAll);
        setSupportedCurrencies(parsedMajor);

        setFromCurrencies(parsedAll);
        setToCurrencies(parsedAll);
      } catch (e) {
        setShowSystemErrorMessage(true);
      }
    }

    getCurrencies();
  }, [initialValues.fromCurrency, initialValues.toCurrency]);

  const getFieldDefaultValue = (
    fieldValue,
    fieldName
  ): { value: string; label: string } => {
    const defaultCurrency = allCurrencies.find((c) => c.value === fieldValue);
    const initialCurrency = allCurrencies.find(
      (c) => c.value === initialValues[fieldName]
    );

    return defaultCurrency || initialCurrency;
  };

  return (
    <Box>
      {gcaOnboarding ? (
        <></>
      ) : (
        <Box marginBottom={4} marginTop={1}>
          <Typography variant="small" fontWeight="bold">
            {i18n.registrationPage.currencyHeader}
          </Typography>
        </Box>
      )}

      <Box marginBottom={2}>
        <Typography variant="body3" color={theme.colors.neutral.s800.hex}>
          {gcaOnboarding
            ? 'What currency pair are you primarily looking to transfer?'
            : i18n.registrationPage.currencySubHeader}
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Field
            name="fromCurrency"
            validate={(selectedCurrency) =>
              validate(
                selectedCurrency,
                supportedCurrencies.filter((c) => c.isMajor),
                i18n,
                'fromCurrency'
              )
            }
          >
            {({
              form: { touched, errors, setFieldValue, setFieldTouched },
              field,
            }) => {
              setFromCurrenciesError(errors['fromCurrency'] === matchError);
              const fieldRequiredError =
                errors['fromCurrency'] ===
                i18n.validationMessages.fieldRequired;
              return (
                <>
                  <Autocomplete
                    {...field}
                    onChange={(e) => {
                      setFieldTouched(field.name);
                      setFieldValue(field.name, e.target.value);
                    }}
                    onBlur={() => {
                      setFieldTouched(field.name);
                    }}
                    defaultValue={getFieldDefaultValue(field.value, field.name)}
                    renderOption={(c) =>
                      c.value ? (
                        c.label
                      ) : (
                        <Box width="100%" padding={0}>
                          <Divider />
                        </Box>
                      )
                    }
                    getOptionDisabled={(option) => option.divider}
                    name={field.name}
                    options={fromCurrencies}
                    trackingId="text-fromCurrency"
                    label={i18n.registrationPage.fromCurrencyLabel}
                    error={fieldRequiredError}
                    helperText={fieldRequiredError && errors['fromCurrency']}
                  />
                  {touched.fromCurrency &&
                    errors.fromCurrency &&
                    !fromCurrenciesError &&
                    !fieldRequiredError && (
                      <Alert severity={'info'} message={errors.fromCurrency} />
                    )}
                </>
              );
            }}
          </Field>
        </Grid>

        <Grid item xs={12} md={6}>
          <Field
            name="toCurrency"
            validate={(selectedCurrency) =>
              validate(
                selectedCurrency,
                supportedCurrencies,
                i18n,
                'toCurrency'
              )
            }
          >
            {({
              form: { touched, errors, setFieldValue, setFieldTouched },
              field,
            }) => {
              setToCurrenciesError(errors['toCurrency'] === matchError);
              const fieldRequiredError =
                errors['toCurrency'] === i18n.validationMessages.fieldRequired;
              return (
                <>
                  <Autocomplete
                    {...field}
                    onChange={(e) => {
                      setFieldTouched(field.name);
                      setFieldValue(field.name, e.target.value);
                    }}
                    onBlur={() => {
                      setFieldTouched(field.name);
                    }}
                    defaultValue={getFieldDefaultValue(field.value, field.name)}
                    renderOption={(c) =>
                      c.value ? (
                        c.label
                      ) : (
                        <Box width="100%" padding={0}>
                          <Divider />
                        </Box>
                      )
                    }
                    getOptionDisabled={(option) => option.divider}
                    name={field.name}
                    options={toCurrencies}
                    trackingId="text-toCurrency"
                    label={i18n.registrationPage.toCurrencyLabel}
                    error={fieldRequiredError}
                    helperText={fieldRequiredError && errors['toCurrency']}
                  />
                  {touched.toCurrency &&
                    errors.toCurrency &&
                    !toCurrenciesError &&
                    !fieldRequiredError && (
                      <Alert severity={'info'} message={errors.toCurrency} />
                    )}
                </>
              );
            }}
          </Field>
        </Grid>
      </Grid>

      {(toCurrenciesError || fromCurrenciesError) && (
        <Alert severity={'info'} message={matchError} />
      )}
    </Box>
  );
};

export default withStore(Currencies);
