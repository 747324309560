import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '../Typography';
import { CSSProperties } from '@material-ui/styles';

interface Styles {
  iconWrapper: CSSProperties;
  title: CSSProperties;
  subTitle: CSSProperties;
  tileBase: CSSProperties;
  tile: CSSProperties;
}

export const styles = (theme: Theme): Styles => {
  const getPaddings = (): { default: string; checked: string } => {
    const paddingY = theme.spacing(3);
    const paddingX = theme.spacing(1);
    return {
      default: `${paddingY}px ${paddingX}px`,
      checked: `${paddingY - 1}px ${paddingX - 1}px`,
    };
  };

  const tileFocusStyles = {
    border: `2px solid ${theme.fox.colors.neutral.s800.hex}`,
    padding: getPaddings().checked,
  };

  return {
    tileBase: {
      minWidth: '100%',
      '& input[type=radio]': {
        opacity: 0,
        position: 'fixed',
        pointerEvents: 'none',
      },
      '& input[type=radio]:focus + label': tileFocusStyles,
      '& input[type=radio]:checked + label': {
        border: `4px solid ${theme.fox.colors.green.s300.hex}`,
        backgroundColor: '#F7FBF9',
      },
      '& input[type="radio"]:checked + label': {
        backgroundColor: theme.fox.colors.green.s100.hex,
        border: `2px solid ${theme.fox.colors.green.s300.hex}`,
        padding: getPaddings().checked,
        '& svg g': {
          stroke: theme.fox.colors.green.s500.hex,
        },
        '& div:first-child': {
          backgroundColor: theme.fox.colors.green.s200.hex,
        },
      },
    },
    tile: {
      transition: 'all .2s',
      display: 'flex',
      flexDirection: 'column',
      flex: `calc(100% - ${theme.spacing(1)}px)`,
      justifyContent: 'center',
      alignItems: 'center',
      width: `calc(100% - ${theme.spacing(1)}px)`,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.04)',
      border: `1px solid ${theme.fox.colors.neutral.s300.hex}`,
      borderRadius: 4,
      backgroundColor: 'white',
      padding: getPaddings().default,
      cursor: 'pointer',
      '&:hover': tileFocusStyles,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all .4s',
      backgroundColor: theme.fox.colors.neutral.s100.hex,
      borderRadius: '100%',
      width: 48,
      height: 48,
      marginBottom: theme.spacing(1),
    },
    title: {
      color: theme.fox.colors.neutral.s800.hex,
      maxWidth: '295px',
    },
    subTitle: {
      marginTop: theme.spacing(1),
      color: theme.fox.colors.neutral.s700.hex,
      maxWidth: '295px',
    },
  };
};

const useStyles = makeStyles((theme: Theme) => styles(theme));

interface Props {
  options: {
    trackingId: string;
    title: string;
    subTitle?: string;
    icon?: JSX.Element;
    value?: string | number;
    defaultChecked?: boolean;
  }[];
  handleClick: (value) => void;
  name: string;
}

const Tile = ({
  options,
  name,
  handleClick,
}: Props): React.ReactElement<Props> => {
  const classes = useStyles({});

  return (
    <FormGroup
      row
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleClick(e.target.value);
      }}
    >
      <Grid container className={classes.tileBase} spacing={3}>
        {options &&
          options.map((option, key) => {
            const id = `${name}${key}`;
            return (
              <Grid item xs={12} sm={6} key={id}>
                <input
                  id={id}
                  type="radio"
                  name={name}
                  value={option.value}
                  data-tracking-id={option.trackingId}
                  defaultChecked={option.defaultChecked}
                />
                <label htmlFor={id} className={classes.tile}>
                  {option.icon && (
                    <Box className={classes.iconWrapper}>{option.icon}</Box>
                  )}
                  <Box className={classes.title}>
                    <Typography
                      textAlign="center"
                      variant="extraSmall"
                      fontWeight="bold"
                    >
                      {option.title}
                    </Typography>
                  </Box>
                  {option.subTitle && (
                    <Box className={classes.subTitle}>
                      <Typography textAlign="center" variant="body3">
                        {option.subTitle}
                      </Typography>
                    </Box>
                  )}
                </label>
              </Grid>
            );
          })}
      </Grid>
    </FormGroup>
  );
};

export default Tile;
