import { cookies, getDomainCookie } from './cookies';

export const useNcpFlow = (
  enableNcpRegistrationFlow: boolean,
  country: string
) => {
  const isRegistrationCountryCoveredByNcp = country === 'AU';
  const isRegistrationEmailDomainGeneric =
    localStorage.getItem('isRegistrationEmailDomainGeneric')?.toLowerCase() ===
    'true';
  const pid = getDomainCookie([cookies.pid, cookies.PARTNER]);
  const hasPartnerCookie = pid !== null;

  return (
    enableNcpRegistrationFlow &&
    isRegistrationCountryCoveredByNcp &&
    !isRegistrationEmailDomainGeneric &&
    !hasPartnerCookie
  );
};
