// this list is based on the "popular" currencies
// which matches any pre-selection based on "region" cookie
export default [
  {
    value: 'AUD',
    label: 'Australian Dollar (AUD)',
    isPopular: true,
    isMajor: false,
  },
  {
    value: 'CAD',
    label: 'Canadian Dollar (CAD)',
    isPopular: true,
    isMajor: false,
  },
  { value: 'EUR', label: 'Euro (EUR)', isPopular: true, isMajor: false },
  {
    value: 'GBP',
    label: 'British Pound (GBP)',
    isPopular: true,
    isMajor: false,
  },
  {
    value: 'HKD',
    label: 'Hong Kong Dollar (HKD)',
    isPopular: true,
    isMajor: false,
  },
  {
    value: 'INR',
    label: 'Indian Rupee (INR)',
    isPopular: true,
    isMajor: false,
  },
  {
    value: 'NZD',
    label: 'New Zealand Dollar (NZD)',
    isPopular: true,
    isMajor: false,
  },
  {
    value: 'SGD',
    label: 'Singapore Dollar (SGD)',
    isPopular: true,
    isMajor: false,
  },
  { value: 'THB', label: 'Thai Baht (THB)', isPopular: true, isMajor: false },
  { value: 'USD', label: 'US Dollar (USD)', isPopular: true, isMajor: false },
];
