const defaultCurrency = {
  'OFX-AU': {
    to: 'USD',
    from: 'AUD',
  },
  'OFX-US': {
    to: 'EUR',
    from: 'USD',
  },
  'OFX-NZ': {
    to: 'USD',
    from: 'NZD',
  },
  'OFX-CA': {
    to: 'USD',
    from: 'CAD',
  },
  'OFX-GB': {
    to: 'EUR',
    from: 'GBP',
  },
  'OFX-IE': {
    to: 'GBP',
    from: 'EUR',
  },
  'OFX-HK': {
    to: 'USD',
    from: 'HKD',
  },
  'OFX-SG': {
    to: 'USD',
    from: 'SGD',
  },
};

export default defaultCurrency;
