import React from 'react';
import TextField, { FoxTextFieldProps } from '@fox/components/TextField';
import { EMAIL_MAX_LENGTH } from 'src/constants/validation';
import { FastField } from 'formik';

type TextFieldProps = Pick<
  FoxTextFieldProps,
  'label' | 'helperText' | 'error' | 'trackingId'
>;

interface InputProps extends TextFieldProps {
  label: string;
  error: boolean;
  helperText: string;
  trackingId: string;
  dirty: boolean;
  fieldName: string;
  id?: string;
}

const EmailInput = ({
  id = 'email',
  dirty,
  fieldName,
  label,
  error,
  helperText,
  trackingId,
}: InputProps): React.ReactElement => {
  return (
    <FastField name={fieldName}>
      {({ field }) => (
        <TextField
          {...field}
          id={id}
          trackingId={trackingId}
          label={label}
          error={dirty && error}
          helperText={dirty && error && helperText}
          inputProps={{ maxLength: EMAIL_MAX_LENGTH + 1 }}
        />
      )}
    </FastField>
  );
};

EmailInput.defaultProps = {
  fieldName: 'email',
};

export default EmailInput;
