import * as yup from 'yup';
import { I18n } from '../../../types';
import { nameRegex } from '../../../util/regexUtil';
import {
  validateEmail,
  validatePaytronEmail,
} from '../../../util/emailHelpers';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
} from 'src/constants/validation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const currencyMatch = function (key, message, country): any {
  const f = function (value): boolean {
    if (!value) return true;

    /*
    // Special rule for NA (DXP-4556)
    */
    if (['CA', 'US'].includes(country) && 'USD' === value) return true;

    return value !== this.options.parent[key];
  };

  return yup.mixed().test('currencyMatch', message, f);
};

yup.addMethod(yup.string, 'currencyMatch', currencyMatch);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const email = (
  i18n,
  enableNcpRegistrationFlow: boolean,
  bypassEmailVerification = null
): any => {
  const messages = i18n.validationMessages;
  return {
    email: yup
      .string()
      .email(i18n.validationMessages.email.invalid)
      .required(messages.email.fieldRequired)
      .max(
        EMAIL_MAX_LENGTH,
        i18n.validationMessages.email.maxFieldLength(EMAIL_MAX_LENGTH)
      )
      .test('verify', '', async function (value) {
        // Empty string for the default message because it's causing FOUT
        const { path, createError } = this;
        const result = await validateEmail(value, bypassEmailVerification);

        if (result.valid) {
          return true;
        }

        if (result.code === 'US:VerifyEmailAddress:0003') {
          result.message = i18n.validationMessages.email.exists;
        }

        return createError({ path, message: result.message });
      })
      .test('verifyPaytron', '', async function (value) {
        // Empty string for the default message because it's causing FOUT

        if (!enableNcpRegistrationFlow) {
          return true;
        }

        const { path, createError } = this;
        const result = await validatePaytronEmail(
          value,
          bypassEmailVerification
        );

        localStorage.setItem(
          'isRegistrationEmailDomainGeneric',
          (!result.isValid).toString()
        );

        if (!result.isExisting) {
          return true;
        }

        return createError({
          path,
          message: i18n.validationMessages.email.exists,
        });
      }),
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const registrationValidationSchema = (
  i18n: I18n,
  enableNcpRegistrationFlow: boolean
): any => {
  const { fieldRequired } = i18n.validationMessages;
  const messages = i18n.validationMessages;

  return yup.object().shape({
    firstName: yup
      .string()
      .required(messages.firstname.fieldRequired)
      .matches(nameRegex, {
        message: i18n.validationMessages.firstname.invalid,
        excludeEmptyString: true,
      })
      .max(
        FIRST_NAME_MAX_LENGTH,
        i18n.validationMessages.firstname.maxFieldLength(FIRST_NAME_MAX_LENGTH)
      ),
    lastName: yup
      .string()
      .required(messages.lastname.fieldRequired)
      .matches(nameRegex, {
        message: i18n.validationMessages.lastname.invalid,
        excludeEmptyString: true,
      })
      .max(
        LAST_NAME_MAX_LENGTH,
        i18n.validationMessages.lastname.maxFieldLength(LAST_NAME_MAX_LENGTH)
      ),
    ...email(i18n, enableNcpRegistrationFlow),
    fromCurrency: yup
      .string()
      .required(messages.currency.fieldRequired)
      //@ts-ignore
      .currencyMatch(
        'toCurrency',
        i18n.validationMessages.currency.currencyMatch
      ),
    toCurrency: yup
      .string()
      .required(messages.currency.fieldRequired)
      //@ts-ignore
      .currencyMatch(
        'fromCurrency',
        i18n.validationMessages.currency.currencyMatch
      ),
    businessType: yup.string().required(messages.businessType.fieldRequired),
    country: yup.string().required(messages.country.fieldRequired),
    transferRange: yup.string().when('country', {
      is: 'NZ',
      then: yup.string().required(messages.transferRange.fieldRequired),
    }),
    userAgreement: yup.bool().oneOf([true], fieldRequired),
  });
};
