import React, { ChangeEvent } from 'react';
import Autocomplete from '@fox/components/Autocomplete';
import { I18n } from 'src/types';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  name: string;
  label: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  i18n: I18n;
  hasError?: boolean;
  helperText?: string;
}

const TransferRangeAutocomplete = ({
  name,
  label,
  onChange,
  i18n,
  hasError,
  helperText,
}: Props): JSX.Element => {
  const {
    values: { transferRange },
  }: FormikContextType<FormikValues> = useFormikContext();
  const defaultValue = { value: transferRange, label: transferRange };
  const { enableDigitalKyc } = useFlags();
  const optionsConfigCurrent = [
    { value: '', label: '' },
    {
      value: i18n.registrationPage.transferRange1,
      label: i18n.registrationPage.transferRange1,
    },
    {
      value: i18n.registrationPage.transferRange2,
      label: i18n.registrationPage.transferRange2,
    },
    {
      value: i18n.registrationPage.transferRange3,
      label: i18n.registrationPage.transferRange3,
    },
    {
      value: i18n.registrationPage.transferRange4,
      label: i18n.registrationPage.transferRange4,
    },
  ];
  const optionsConfigDKYC = [
    { value: '', label: '' },
    {
      value: i18n.registrationPage.newTransferRange.range1,
      label: i18n.registrationPage.newTransferRange.range1,
    },
    {
      value: i18n.registrationPage.newTransferRange.range2,
      label: i18n.registrationPage.newTransferRange.range2,
    },
    {
      value: i18n.registrationPage.newTransferRange.range3,
      label: i18n.registrationPage.newTransferRange.range3,
    },
    {
      value: i18n.registrationPage.newTransferRange.range4,
      label: i18n.registrationPage.newTransferRange.range4,
    },
    {
      value: i18n.registrationPage.newTransferRange.range5,
      label: i18n.registrationPage.newTransferRange.range5,
    },
    {
      value: i18n.registrationPage.newTransferRange.range6,
      label: i18n.registrationPage.newTransferRange.range6,
    },
    {
      value: i18n.registrationPage.newTransferRange.range7,
      label: i18n.registrationPage.newTransferRange.range7,
    },
    {
      value: i18n.registrationPage.newTransferRange.range8,
      label: i18n.registrationPage.newTransferRange.range8,
    },
  ];

  const optionsConfig = enableDigitalKyc
    ? optionsConfigDKYC
    : optionsConfigCurrent;

  return (
    <Autocomplete
      name={name}
      defaultValue={defaultValue}
      label={label}
      trackingId="transfer-range-autocomplete"
      options={optionsConfig}
      onChange={onChange}
      helperText={helperText}
      error={hasError}
      getOptionDisabled={(option) => !option.value}
    />
  );
};

export default TransferRangeAutocomplete;
